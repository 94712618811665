<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="项目/表单名称">
              <a-input v-model="queryParam.name" @pressEnter="getDataList" placeholder="请输入项目/表单名称"/>
            </a-form-item>
          </a-col>
          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="getDataList">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="$refs['action_form'].append(currentId)" icon="plus">新增表单</a-button>
    </div>

    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="dataList"
      :pagination="pagination"
    >
      <a-list-item slot="renderItem" slot-scope="item, index">
        <div style="width: 50px; text-align: center; cursor: pointer; margin-right: 10px">
          <a-icon style="font-size: 40px;" :type="type(item.type)"/>
        </div>
        <a slot="actions" @click="edit(item)" v-if="item.type == 2">编辑</a>
        <a slot="actions" v-if="item.type == 2">
          <a-popconfirm placement="topRight" title="确认删除？" okText="确认" cancelText="取消" @confirm.stop="() => del(item)">
            <span>删除</span>
          </a-popconfirm>
        </a>

        <a-list-item-meta :description="getDescription(item)">
          <a slot="title" style="cursor: pointer">{{ item.name }}</a>
        </a-list-item-meta>

        <div v-if="item.type == 2">
          <a-switch
            :checked="item.status===1"
            @change="handleSwitchStatus(item.id)"/>
        </div>
      </a-list-item>
    </a-list>

    <form-modal ref="action_form" @ok="handleOk"/>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = !previewVisible">
      <img alt="example" style="width: 100%" :src="getFileUrl(previewImage)" />
    </a-modal>
  </a-card>
</template>

<script>
import { list, del, switchStatus } from '@/api/business/patrolConfig'
import FormModal from "@/views/business/patrol-config/components/FormModal";
const frequencyUnitMap = {
  'YEAR': '年',
  'MONTH': '月',
  'WEEK': '周',
  'DAY': '天'
}
export default {
  name: 'ProjectAndFormList',
  components: {
    FormModal,
  },
  props: {
    currentId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      frequencyUnitMap,
      previewVisible: false,
      previewImage: '',
      loading: false,
      advanced: false,
      queryParam: {
        parentId: 0,
        name: '',
        pageNum: 1,
        pageSize: 8,
      },
      dataList: [],
      total: 0,
      pagination: {
        onChange: pageNum => {
          this.queryParam.pageNum = pageNum
          this.getDataList()
        },
        pageSize: 8,
        total: 0
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.loading = true
      this.queryParam.parentId = this.currentId
      list(this.queryParam).then(res => {
        if (res.code === 200) {
          this.dataList = res.rows ? this._.orderBy(res.rows, 'sort') : []
          this.pagination.total = res.total
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleOk() {
      this.getDataList()
    },

    refresh() {
      this.getDataList()
    },

    resetForm() {
      this.queryParam = {
        parentId: 0,
        name: '',
        pageNum: 1,
        pageSize: 8,
      }
      this.pagination.current = 1
      this.getDataList()
    },

    edit(item) {
      this.$refs['action_form'].edit(item, false)
    },

    handleSwitchStatus(id) {
      this.loading = true;
      switchStatus(id).then(res =>{
        if (res.code === 200) {
          this.getDataList()
        }
      }).finally(()=> {
        this.loading = false;
      })
    },

    del(item) {
      this.loading = true;
      del(item.id).then(res =>{
        if (res.code === 200) {
          this.$notification.success({message: '删除成功'})
          this.getDataList()
        }
      }).finally(()=> {
        this.loading = false;
      })
    },

    getDescription(item) {
      if (item.type == 2) {
        return '提交频率：' + item['frequency'] + frequencyUnitMap[item.frequencyUnit] + item['frequencyNum'] + '次' + `（排序号：${item.sort}）`
      }
      return ''
    },
    type(type) {
      return type == 1 ? 'folder-open' : 'file-text'
    },
    getFileUrl(src) {
      return process.env.VUE_APP_API_FILE_URL + src
    },
    async handlePreview(item, isName) {
      if (!isName && this.type(item.suffix) == 'img') {
        this.previewImage = item.url
        this.previewVisible = true
        return
      }
      let link = document.createElement('a');
      link.href = item.url;
      link.click();
    },

  }
}
</script>

<style lang="less">
.table-operator {
  margin-bottom: 2px;
}
button {
  margin-right: 8px;
}
</style>
