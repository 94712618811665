<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="父级项目"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-tree-select
            v-decorator="['parentId', {rules: [{required: true, message: '请选择父级项目'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }"
            placeholder="请选择父级项目"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>

        <a-form-item
          label="项目名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-input
            placeholder="请输入项目名称"
            v-decorator="['name', {rules: [{required: true, message: '请输入项目名称'}]}]"
          />
        </a-form-item>
        <a-form-item v-if="modalType" label="状态" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-radio-group v-decorator="['status', { initialValue: 1, rules: [{ required: true, message: '状态不能为空' }] }]" >
            <a-radio :value="1">正常</a-radio>
            <a-radio :value="0">停用</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="排序" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
            v-decorator="['sort', { initialValue: 1, rules: [{ required: true, message: '排序不能为空' }] }]"
            :min="1"
            :max="10000"
            style="width: 100%;"/>
        </a-form-item>
        <a-form-item label="其它字段" :label-col="labelCol" :wrapper-col="wrapperCol"></a-form-item>
        <list-item-add :columns="otherFieldColumns" ref="list_item_add"></list-item-add>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { listProjectTree, editProjectConfig, addProjectConfig } from '@/api/business/patrolConfig'
import pick from 'lodash.pick'
import ListItemAdd from "@/views/business/patrol-config/components/ListItemAdd";

// 表单字段
const fields = [
  'parentId', 'name', 'status', 'sort'
]

// 其它字段表头
const otherFieldColumns = [
  {
    title: '字段类型',
    type: 'select',
    dataIndex: 'fieldType',
    width: '15%',
    scopedSlots: { customRender: 'fieldType' },
    // TEXT-文本 IMAGE-图片 FILE-文件 LABEL-标签
    options: [
      {
        label: '文本',
        value: 'TEXT'
      },{
        label: '图片',
        value: 'IMAGE'
      },{
        label: '文件',
        value: 'FILE'
      },{
        label: '标签',
        value: 'LABEL'
      },{
        label: '功能',
        value: 'FUNC'
      },
    ]
  },
  {
    title: '字段名称',
    type: "text",
    dataIndex: 'fieldName',
    width: '25%',
    scopedSlots: { customRender: 'fieldName' },
  },
  {
    title: '字段值',
    type: "text",
    dataIndex: 'fieldValue',
    width: '25%',
    scopedSlots: { customRender: 'fieldValue' },
  },{
    title: '备注',
    type: "text",
    dataIndex: 'fieldDetails',
    width: '20%',
    scopedSlots: { customRender: 'fieldDetails' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: '140px',
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'ProjectModal',
  components: {ListItemAdd},
  data () {
    return {
      otherFieldColumns,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      // 当前类型 true编辑 false新增
      modalType: true,
      // 编辑id
      id: null,
      // 编辑key
      key: null,
      //  标题
      title: null
    }
  },
  methods: {
    /**
     * 修改
     */
    edit (record) {
      this.title = '修改项目'
      this.modalType = true
      this.id = record.id
      this.key = record.key
      this.handleTree()
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick(record, fields))
        this.$refs['list_item_add'].init(record['otherInfoList'])
      })
    },
    /**
     * 添加
     */
    append (currentId) {
      if (currentId == null || currentId == 'undefined') {
        currentId = 0
      }
      this.title = '新建项目'
      this.modalType = false
      this.handleTree()
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick({'parentId': currentId}, fields))
        this.$refs['list_item_add'].reset()
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          const otherInfoList = this.$refs['list_item_add'].getDataList()
          values['otherInfoList'] = otherInfoList
          if (this.modalType) {
            this.editSubmit(values)
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 修改提交
     */
    editSubmit (values) {
      editProjectConfig(Object.assign(values, { id: this.id })).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$notification.success({message: '修改成功'})
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    /**
     * 添加提交
     */
    addSubmit(values) {
      addProjectConfig(values).then((res) => {
        if (res.code === 200) {
          this.$notification.success({message: '新增成功'})
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.modalType = undefined
      this.id = undefined
      this.title = undefined
      this.$refs['list_item_add'].reset()
    },

    handleTree () {
      this.loading = true
      listProjectTree(0).then((res) => {
        if (res['code'] === 200) {
          if (this.modalType) {
            this.deleteThisItem(res.rows)
          }
          this.treeData = [{ name: '根目录', key: 0, parentId: 0, children: null }]
          this.treeData[0].children = res.rows
        }
      }).finally(() => {
        this.loading = false
      })
    },

    deleteThisItem (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === this.key) {
          delete data[i]
        }
        if (data[i] && data[i].children) {
          this.deleteThisItem(data[i].children)
        }
      }
    }
  }
}
</script>
